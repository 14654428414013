import { MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL, STERILIZATION_SPECIFICATIONS_BRITESTACK } from "@kraftheinz/common";
import { n as normalizeComponent } from "./index.js";
import "lodash";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("create-modal-composer", { attrs: { "title": "Add Britestack" }, scopedSlots: _vm._u([{ key: "actions", fn: function(ref) {
    var cancel = ref.cancel;
    var isLoading = ref.isLoading;
    var submit = ref.submit;
    return [_c("a-button", { on: { "click": cancel } }, [_vm._v("Cancel")]), _c("a-button", { attrs: { "loading": isLoading, "type": "primary" }, on: { "click": submit } }, [_vm._v("Add")])];
  } }]) }, [_c("item-code-input", { key: "bsItemCode", attrs: { "label": _vm.label, "reference": "sterilization.common.item-masters", "rules": "required", "search-by": "ItemCode" } })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "CreateBritestack",
  inject: ["can"],
  computed: {
    label() {
      if (this.can(MAINTAIN_PERMISSION))
        return "Inventory Code";
      if (this.$can(MAINTAIN_PERMISSION, STERILIZATION_SPECIFICATIONS_BRITESTACK_TRIAL))
        return "Inventory Code (trial code only)";
      return "Inventory Code";
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var CreateBritestack = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("resource", { attrs: { "name": "sterilization.common.item-masters", "api-url": _vm.apiUrl, "resource-id-name": "itemCode" } }), _c("resource", { attrs: { "page": _vm.page, "name": "sterilization.britestacks", "api-url": _vm.apiUrl, "redirect-route": "/sterilization-specifications/britestacks/:id" } }, [_c("create-britestack")], 1)], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    CreateBritestack
  },
  data() {
    return {
      page: STERILIZATION_SPECIFICATIONS_BRITESTACK,
      apiUrl
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
